import React, { useEffect } from "react";
import HomepageLayout from "src/layouts/homepage";
import "./dos.css"; // Import CSS file here

export default function DoNotSellInfo() {
    return (
        <HomepageLayout>
                <iframe src="https://app.termly.io/notify/0d21ce7c-3798-470d-9d45-e1ae2d2d206b" title="description"></iframe>
        </HomepageLayout>
    );
}
